import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body preview-panel" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = {
  key: 0,
  class: "post-info"
}
const _hoisted_4 = { class: "col-md-6 col-xs-6 post-count-view" }
const _hoisted_5 = {
  key: 2,
  class: "alert alert-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", {
    class: ["card no-margin no-border", { loading: _ctx.loading }]
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", {
          class: ["col-md-3 post-content", { 'post-content-padding': !_ctx.post.no_margin_layout }]
        }, [
          (
              !_ctx.errors.length && _ctx.post.show_view_count && _ctx.post.view_count >= 0
            )
            ? (_openBlock(), _createBlock("div", _hoisted_3, [
                _createVNode("small", _hoisted_4, "조회 " + _toDisplayString(_ctx.post.view_count), 1)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.errors.length)
            ? (_openBlock(), _createBlock("div", {
                key: 1,
                innerHTML: _ctx.post.desc,
                ref: "content"
              }, null, 8, ["innerHTML"]))
            : _createCommentVNode("", true),
          (_ctx.errors.length)
            ? (_openBlock(), _createBlock("div", _hoisted_5, " Post is not found. "))
            : _createCommentVNode("", true)
        ], 2)
      ])
    ])
  ], 2))
}