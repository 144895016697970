
import { defineComponent, ref, onMounted, nextTick, watch } from 'vue';
import { useRouter } from 'vue-router';
import dateTimeHelper from '../../helper/datetime.helper';
import { ApiService } from '../../services/backend-api/api.services';
import { default as PostModel } from '../../models/post.model';

export default defineComponent({
  components: {},
  setup() {
    const router = useRouter();
    let loading = ref(false);
    const content = ref<HTMLElement | null>(null);
    const allImagesLoaded = ref(false);
    let errors = ref<string[]>([]);
    let post = ref<any>({
      title: '',
      category_id: '',
      published_at: 0,
      pin: false,
      show: false,
      landing_url: '',
      contents_url: '',
      desc: '',
      thumbnail: '',
      created_at: 0,
      updated_at: 0,
      view_count: 0,
    });
    let postId = router.currentRoute.value.params.id;
    onMounted(async () => {
      const checkImagesLoaded = () => {
        if (!content.value) return;

        const images = content?.value?.querySelectorAll('img');
        if (images.length === 0) {
          allImagesLoaded.value = true;
          console.log('📢 모든 이미지 로드 완료!');
          return;
        }

        let loadedCount = 0;
        images.forEach((img: any) => {
          if (img.complete) {
            loadedCount++;
          } else {
            img.addEventListener('load', () => {
              loadedCount++;
              if (loadedCount === images.length) {
                allImagesLoaded.value = true;
                const height = document.body.scrollHeight;
                console.log(height);
                window.parent.postMessage({ height: height }, '*');
              }
            });
          }
        });

        // 혹시라도 `complete` 상태인 이미지가 이미 있는 경우 확인
        if (loadedCount === images.length) {
          allImagesLoaded.value = true;
          console.log('📢 모든 이미지 로드 완료!');
        }
      };

      const apiService = new ApiService();
      let previewData = await apiService.previewPost(postId);
      if (previewData.status) {
        post.value = previewData.data;
        allImagesLoaded.value = false;
        await nextTick(); // DOM 업데이트 후 실행
        checkImagesLoaded();

        await apiService.updatePostViewCount(postId);
      } else {
        errors.value.push(previewData.data.message);
      }
      loading.value = false;

      handleLinks();

      function handleLinks() {
        document.querySelectorAll('a').forEach(el => {
          el.addEventListener('click', e => {
            const href = el.getAttribute('href');

            // 특정 링크 확인
            e.preventDefault(); // 링크 기본 동작 막기
            window.parent.postMessage({ url: href }, '*');
          });
        });
      }

      function checkPcLoaded() {
        const userAgent = navigator.userAgent || navigator.vendor;

        // 모바일 디바이스 체크
        if (/android/i.test(userAgent)) {
          return false;
        }
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return false;
        }

        return true;
      }
    });

    return {
      errors,
      post,
      loading,
      content,
      dateTimeHelper,
    };
  },
});
