
import { defineComponent, computed } from 'vue';
import { useRouter } from 'vue-router';
// import markerSDK from '@marker.io/browser';

const defaultLayout = 'default';

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter();

    const layout = computed(
      () => `${currentRoute.value.meta.layout || defaultLayout}-layout`,
    );

    // onMounted(async () => {
    //   try {
    //     const excludeMarkersIoPath = ['/post/preview/'];

    //     setTimeout(async () => {
    //       let useMarkerIo = true;
    //       excludeMarkersIoPath.forEach(path => {
    //         if (currentRoute.value.path.includes(path) && useMarkerIo) {
    //           useMarkerIo = false;
    //         }
    //       });
    //       if (useMarkerIo) {
    //         const widget = await markerSDK.loadWidget({
    //           project: '677e04bb18fd1ef339b1d120',
    //         });

    //         // 필요한 경우 추가 설정
    //         widget.setReporter({
    //           email: 'owen@sgma.io',
    //           fullName: '오언',
    //         });
    //       }
    //     }, 500);
    //   } catch (error) {
    //     console.error('Failed to load Marker.io widget:', error);
    //   }
    // });

    return {
      layout,
      currentRoute,
    };
  },
});
